<template>
  <div>
    <b-navbar
      v-if="$route.name !== 'consumer-login'"
      toggleable="sm"
      type="dark"
      variant="csub"
    >
      <b-navbar-brand to="contracted-list"> PLR+ </b-navbar-brand>
      <b-nav-text>データ取引会員サイト {{ consumerName }}</b-nav-text>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="contracted-list" active-class="link-active" exact>
            履行可能データ提供契約
          </b-nav-item>
          <b-nav-item v-on:click="logout()">LOGOUT</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      consumerName: sessionStorage.getItem('consumerName'),
    }
  },
  watch: {
    $route: function () {
      this.consumerName = sessionStorage.getItem('consumerName')
    },
  },
  methods: {
    logout() {
      console.log('** logout **')
      sessionStorage.removeItem('consumerName')
      this.$cookies.remove('operator_type3_session')
      this.$cookies.remove('XSRF-TOKEN')
      this.$cookies.remove('_csrf')
      this.$cookies.remove('consumer_domain')
      this.$router.push({ name: 'consumer-login' })
    },
  },
}
</script>
